import { FederationRuntimePlugin } from '@module-federation/enhanced/runtime';
import { getConfigs } from './remoteConfigs/configs';
import { getRemoteOverrides } from './utils/getRemoteOverrides';

const runtimePlugin: () => FederationRuntimePlugin = function () {
  console.log('Initializing a4b-runtime-plugin');
  return {
    name: 'a4b-runtime-plugin',
    beforeInit(args) {
      const { userOptions } = args;
      const shared = userOptions.shared;
      if (shared) {
        Object.keys(shared).forEach((sharedKey) => {
          const sharedConfigs = shared[sharedKey];
          const arraySharedConfigs = Array.isArray(sharedConfigs)
            ? sharedConfigs
            : [sharedConfigs];
          arraySharedConfigs.forEach((s) => {
            s.strategy = 'loaded-first';
          });
        });
      }
      return args;
    },
    async beforeRequest(args) {
      const envOverrides = getRemoteOverrides();
      const configs = getConfigs();

      const isLocal =
        !!window.location.host.match(/localhost/) ||
        !!window.location.host.match(/\.local/);

      const isProd =
        !window.location.host.match(/business\.(\w+)\.alle\.com/) && !isLocal;

      args.options?.remotes?.forEach((remote) => {
        if (!configs[remote.name]) return;

        const remoteConfig = configs[remote.name];

        const envOverride = envOverrides.find((param) =>
          param.startsWith(remoteConfig.overrideName)
        );

        if (isLocal && !envOverride) {
          (
            remote as (typeof args.options.remotes)[0] & {
              entry: string;
            }
          ).entry = remoteConfig.envs.dev;
        } else if (envOverride && !isProd) {
          const env = envOverride.replace(`${remoteConfig.overrideName}-`, '');
          (
            remote as (typeof args.options.remotes)[0] & {
              entry: string;
            }
          ).entry = remoteConfig.envs[env];
        } else {
          (
            remote as (typeof args.options.remotes)[0] & {
              entry: string;
            }
          ).entry = `${window.location.protocol}//${window.location.host}/federation/${remoteConfig.manifestNamespace}/mf-manifest.json`;
        }
      });

      return args;
    },
  };
};

export default runtimePlugin;
