const OVERRIDE_QUERY_PARAM = 'remoteOverride';
const OVERRIDE_STORAGE_KEY = 'module_federation_remote_override';

export const getSavedOverrides = () => {
  const saved = localStorage.getItem(OVERRIDE_STORAGE_KEY);

  if (!saved) return [];

  try {
    return JSON.parse(saved);
  } catch (_) {
    return [];
  }
};

export const saveOverrides = (envOverrides: string[]): void =>
  localStorage.setItem(OVERRIDE_STORAGE_KEY, JSON.stringify(envOverrides));

export const getQueryOverrides = () => {
  const overrides = new URLSearchParams(window.location.search).getAll(
    OVERRIDE_QUERY_PARAM
  );

  return overrides.filter((override) => override.indexOf('-') !== -1);
};

export interface OverrideMap {
  [key: string]: boolean;
}

export const filterSavedOverrides = (query: string[], saved: string[]) => {
  const queryOverrideMap = query.reduce(
    (map: OverrideMap, override: string) => {
      const key: string = override.split('-')[0];
      map[key] = true;
      return map;
    },
    {}
  );

  return saved.filter((override) => !queryOverrideMap[override.split('-')[0]]);
};

export const getRemoteOverrides = (): string[] => {
  const queryOverrides = getQueryOverrides();

  const envOverrides = [
    ...queryOverrides,
    ...filterSavedOverrides(queryOverrides, getSavedOverrides()),
  ];

  saveOverrides(envOverrides);

  return envOverrides;
};
