import onboardingRemote from './onboardingRemote.json';

export interface RemoteConfig {
  name: string;
  remoteId: string;
  overrideName: string;
  manifestNamespace: string;
  envs: Envs;
}

export interface Envs {
  [key: string]: string;
}

export const configs = [onboardingRemote];

export const getConfigs = () =>
  configs.reduce((map: { [key: string]: RemoteConfig }, config) => {
    map[config.remoteId] = config;
    return map;
  }, {});
